import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const NewsCard = ({post}) => (
  <Link to={post.fields.slug} className="news-preview-item">
    <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} alt="" style={{ borderRadius: '2px'}} />
    <div className="news-title">
      <h1 className="title">{post.frontmatter.title}</h1>
      <p><small className="accent-color">Posted: {post.frontmatter.date}</small></p>
      <Link className="button is-white is-rounded" to={post.fields.slug}>Read More</Link>
    </div>
  </Link>
)

export default NewsCard